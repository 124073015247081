import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from './components/ToastWrapper/ToastContext';
import { DrawerProvider } from './components/DrawerWrapper/DrawerContext';
import { ModalProvider } from './components/ModalWrapper/ModalContext';
import { StoreContext } from './hooks/useStore';

import store from './store';

import reportWebVitals from './reportWebVitals';
import axiosConfig from './config/axiosConfig';
import theme from './config/theme';
import App from './App';

import './index.css';

axiosConfig();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StoreContext.Provider value={store}>
        <ToastProvider>
          <BrowserRouter>
            <DrawerProvider>
              <ModalProvider>
                <App />
              </ModalProvider>
            </DrawerProvider>
          </BrowserRouter>
        </ToastProvider>
      </StoreContext.Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
