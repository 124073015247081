import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useIconStyles = makeStyles(() =>
  createStyles({
    root: {
      borderRadius: '30px',
      padding: '0.5rem 0.1rem',
      width: 'auto',
      minWidth: '30px',
    },
  }),);

export const useSnackbarStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'unset',
      transform: 'unset',
      left: 'unset',
      right: 'unset',
      top: 'unset',
    },
  }),);

export const useAlertStyles = makeStyles((theme) =>
  createStyles({
    root: () => ({
      borderRadius: '40px',
      backgroundColor: theme.itaTheme.white,
      color: theme.itaTheme.black,
      fontSize: '14px',
      fontWeight: 'normal',
      maxWidth: '500px',
      boxShadow: `0px 2px 10px -2px rgb(0 0 0 / 14%),
      0px 2px 2px 0px rgb(0 0 0 / 7%),
      0px 1px 8px 0px rgb(0 0 0 / 6%)`,
      '& .MuiAlertTitle-root': {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '16px',
        color: theme.palette.success.main,
      },
      '& .MuiAlert-icon': {
        padding: '12px 0',
      },
      '&.toast-Error': {
        '& .MuiAlertTitle-root': {
          color: theme.palette.error.main,
        },
      },
    }),
  }),);
