import { ElementType, useState, createContext, FC } from 'react';
import ModalWrapper from '.';

export interface IModalComponent {
  component: ElementType;
  props?: unknown;
}

interface IModalContext {
  modalComponent: IModalComponent | null;
  setModalComponent: (component: IModalComponent | null) => void;
}

const ModalContext = createContext<IModalContext>({
  modalComponent: null,
  setModalComponent: () => {
    // Empty function
  },
});

const ModalProvider: FC = ({ children }) => {
  const [modalComponent, setModalComponent] = useState<IModalComponent | null>(null);

  const handleSetModalComponent = (component: IModalComponent | null): void => {
    setModalComponent(component);
  };

  return (
    <ModalContext.Provider value={{ modalComponent, setModalComponent: handleSetModalComponent }}>
      {children}
      <ModalWrapper />
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
