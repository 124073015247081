const ROUTES = {
  LANDING: '/landing',
  BROWSER: '/browser',
  LOGIN: '/login',
  SIGN_IN: '/sign-in',
  FORGOT_PASS: '/forgot-pass',
  REGISTER_VEHICLES: '/register-vehicles',
  VEHICLE_DETAIL: '/vehicle/:registerId',
  USER_PROFILE: '/user-profile',
};

export default ROUTES;
