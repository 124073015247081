import { createTheme } from '@material-ui/core/styles';

export const itaTheme = {
  // primary
  ita: '#087ccd',
  brandBlue: '#4db5e2',
  pine: '#014651',
  // secondary
  ferrari: '#FF5D51',
  water: '#5ED9ED',
  pineapple: '#FFC61F',
  // complementary
  tea: '#DCEEDF',
  opal: '#026E5E',
  peacock: '#014651',
  arctic: '#C2EBF2',
  cerulean: '#4367E2',
  navy: '#1B2C77',
  bottle: '#203C4D',
  vanilla: '#EFF3A1',
  mellow: '#E8D87D',
  cheddar: '#DF9A00',
  juice: '#F38A1B',
  chocolate: '#332E24',
  ballet: '#F2CECB',
  salmon: '#FA9E9F',
  carmine: '#992922',
  merlot: '#EB001B',
  // gray scale
  cotton: '#FFFFFF',
  pearl: '#FAFAFA',
  stone: '#EBEEF2',
  dolphin: '#BFC5CC',
  shark: '#8F9499',
  extraGrey: '#4C545B',
  charcoal: '#303133',
  obsidian: '#000000',
  crow: '#131317',
  panter: '#292929',
  darkSky: '#1f1e22',
  ink: 'rgba(18, 18, 18, 0.9)',
  oil: '#121212',
  // expenses
  ocher: '#D58807',
  mango: '#FEBB1A',
  honey: '#E2D16A',
  lime: '#EBF390',
  ruby: '#FC4440',
  crimson: '#851A1A',
  coral: '#F68A8E',
  rosewood: '#EEC2BF',
  wine: '#960042',
  rose: '#D4005B',
  barbie: '#FB1582',
  bubblegum: '#DB6AA0',
  eggplant: '#5B0B6A',
  jamisne: '#8900A0',
  magenta: '#C327DE',
  orchid: '#B36CC0',
  midnight: '#151D63',
  iris: '#344DDB',
  sky: '#51D1E8',
  aqua: '#B6E7EF',
  forest: '#073640',
  emerald: '#0D5C4B',
  jade: '#31997C',
  mint: '#25D19E',
  spring: '#42F19C',
  apple: '#A5D0AB',
};

const theme = createTheme({
  overrides: {
    MuiAlert: {
      filledSuccess: {
        backgroundColor: itaTheme.extraGrey,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: itaTheme.darkSky,
      },
      rounded: {
        borderRadius: 11,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 15,
        background: itaTheme.darkSky,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 18,
      },
    },
    MuiTab: {
      root: {
        fontSize: 14,
        fontWeight: 600,
      },
    },
  },
  itaTheme,
  palette: {
    type: 'dark',
    primary: {
      main: itaTheme.brandBlue,
      contrastText: itaTheme.cotton,
    },
    secondary: {
      main: itaTheme.charcoal,
      light: itaTheme.cotton,
      contrastText: itaTheme.dolphin,
    },
    background: {
      paper: itaTheme.cotton,
      default: itaTheme.pearl,
    },
    success: {
      main: itaTheme.cerulean,
    },
    error: {
      main: itaTheme.ferrari,
      light: itaTheme.salmon,
      dark: itaTheme.merlot,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 16,
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: 80,
      fontWeight: 'bold',
      margin: 0,
    },
    h2: {
      fontSize: 40,
      fontWeight: 'bold',
      margin: 0,
      color: itaTheme.cotton,
    },
    h3: {
      fontSize: 20,
      fontWeight: 'bold',
      margin: 0,
      color: itaTheme.cotton,
    },
    h4: {
      fontSize: 16,
      fontWeight: 'normal',
      margin: 0,
      color: itaTheme.cotton,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
      margin: 0,
      color: itaTheme.cotton,
    },
    h6: {
      fontSize: 12,
      fontWeight: 'normal',
      margin: 0,
      color: itaTheme.cotton,
    },
  },
});

export default theme;
