import { toJS } from 'mobx';
import { applySnapshot, getRoot, types } from 'mobx-state-tree';
import ObjectPath from 'object-path';

const Common = types
  .model({})
  .views((self) => ({
    get globalStore() {
      return getRoot(self);
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = toJS(self);
      },
      resetStore() {
        applySnapshot(self, { ...initialState });
      },
      replace(data) {
        applySnapshot(self, data);
      },
      updateField: (field, value) => {
        ObjectPath.set(self, field, value);
        applySnapshot(self, self);
      },
      delay: (ms) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  });

export default Common;
