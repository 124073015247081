import { createStyles, makeStyles } from '@material-ui/core/styles';

const styles = (theme) => createStyles({
  header: {
    paddingTop: 20,
  },
  headerReverse: {
    marginLeft: 'auto',
  },
  subtitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  subtitleContainerReverse: {
    flexDirection: 'row-reverse',
  },
  subtitle: {
    textTransform: 'uppercase',
    lineHeight: '1em',
    color: theme.itaTheme.shark,
    fontSize: 12,
  },
  title: {
    padding: '0 55px',
    margin: '5px 0 15px 0',
    fontSize: 24,
  },
  icon: {
    background: theme.itaTheme.ita,
    width: 25,
    height: 25,
    color: theme.itaTheme.cotton,
    margin: '0 15px',
    '&:hover': {
      background: theme.itaTheme.brandBlue,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
  },
});

export default makeStyles(styles);
