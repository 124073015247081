import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route } from 'react-router-dom';

import useStore from '../../hooks/useStore';
import ROUTES from '../../constants/routes';

const UnAuthRoute = ({ component: Component, ...routeProps }) => {
  const { loginStore, userStore } = useStore();
  const isAuthenticated = loginStore.isAuthenticated === '1';

  useEffect(() => {
    userStore.getUserData();
  }, []);

  console.log(loginStore.redirectionPath);
  if (isAuthenticated && loginStore.redirectionPath !== '') {
    return (
      <Redirect from="*" to={loginStore.redirectionPath} />
    );
  }

  if (!isAuthenticated) {
    return (
      <Route
        {...routeProps}
        render={(props) => (
          <Component {...props} />
        )}
      />
    );
  }

  return (
    <Redirect from="*" to={ROUTES.LANDING} />
  );
};

export default observer(UnAuthRoute);
