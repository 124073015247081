export default {
  login: '/login',
  getUserData: '/user',
  createUser: '/user/create',
  resetPass: '/user/reset/pass',
  getBrands: '/filters/brands',
  getYears: (brandId) => `/filters/years/${brandId}`,
  getModels: (brandId, year) => `/filters/models/${brandId}/${year}`,
  getSubModels: (modelId, year) => `/filters/sub-models/${modelId}/${year}`,
  getEngines: (subModelId, year) => `/filters/engine/${subModelId}/${year}`,
  createVehicleRegister: '/registers/create',
  getVehiclesList: 'vehicles/list',
  getVehiclesRegisteredBrands: '/filters/registers/brands',
  getVehiclesRegisteredModels: (brand) => `/filters/registers/models/${brand}`,
  getVehiclesRegisteredYears: '/filters/registers/years',
  getVehiclesRegisteredStates: '/filters/registers/states',
  getVehicleDetail: (registerId) => `/vehicles/vehicle/${registerId}`,
  getAvailableVehiclesUserList: (userId) => `/vehicles/available-list/${userId}`,
  getVehiclesUserList: (userId) => `/vehicles/list/${userId}`,
  createOffer: '/offers/create',
  updateUserEmail: '/user/update/email',
  updateUserPass: '/user/update/pass',
  updateVehiclePrice: '/vehicles/update/price',
  updateVehicleDescription: '/vehicles/update/description',
  deleteVehicle: '/vehicles/delete',
  getUserOffersList: (userId) => `/offers/list/${userId}`,
  acceptOffer: '/offers/accept',
  rejectOffer: '/offers/reject',
};
