import { types } from 'mobx-state-tree';

// stores
import UserStore from './user';
import LoginStore from './login';
import SignInStore from './signIn';
import RegisterVehiclesStore from './registerVehicles';
import VehiclesStore from './vehicles';
import OffersStore from './offers';

export const GlobalStore = types.model({
  userStore: UserStore,
  loginStore: LoginStore,
  signInStore: SignInStore,
  registerVehiclesStore: RegisterVehiclesStore,
  vehiclesStore: VehiclesStore,
  offersStore: OffersStore,
});

const store = GlobalStore.create({
  userStore: UserStore.create({}),
  loginStore: LoginStore.create({}),
  signInStore: SignInStore.create({}),
  registerVehiclesStore: RegisterVehiclesStore.create({}),
  vehiclesStore: VehiclesStore.create({}),
  offersStore: OffersStore.create({}),
});

export default store;
