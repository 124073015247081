import { types, applySnapshot, flow, cast } from 'mobx-state-tree';
import axios from 'axios';

import Common from '../common';
import endpoints from '../../config/endpoints';

const debug = require('debug');

const log = debug('store:offers:log');
const errorLog = debug('store:offers:error');

const SelectedVehicleItemType = types.model({
  id: types.string,
  userId: types.string,
  registerDate: types.string,
  vehicleId: types.string,
  brand: types.string,
  model: types.string,
  subModel: types.string,
  engine: types.string,
  year: types.string,
  frontImg: types.string,
  frontLeftImg: types.string,
  frontRightImg: types.string,
  backLeftImg: types.string,
  backRightImg: types.string,
  backImg: types.string,
  interiorLeftImg: types.string,
  interiorImg: types.string,
  interiorBackImg: types.string,
  leftImg: types.string,
  rightImg: types.string,
  color: types.string,
  state: types.string,
  city: types.string,
  km: types.string,
  price: types.string,
  description: types.string,
  available: types.string,
});

const OfferListType = types.model({
  available: types.string,
  interchangeType: types.string,
  offerDate: types.string,
  offerId: types.string,
  offerPrice: types.string,
  offerRegisterId: types.string,
  offerType: types.string,
  offerUserId: types.string,
  offerVehicleData: types.optional(types.array(SelectedVehicleItemType), []),
  ownerUserId: types.string,
  ownerVehicleData: types.optional(types.array(SelectedVehicleItemType), []),
  registerId: types.string,
});

const OffersStore = types.compose(
  Common,
  types.model({
    submitting: false,
    submitError: false,
    submitErrorMsg: '',
    offerType: '',
    offerPrice: '',
    offerPriceError: false,
    interchangeType: '',
    interchangeTypeError: false,
    selectedUserVehicleId: '',
    selectedUserVehicleIdError: false,
    selectedUserVehicle: types.optional(SelectedVehicleItemType, {
      id: '',
      userId: '',
      registerDate: '',
      vehicleId: '',
      brand: '',
      model: '',
      subModel: '',
      engine: '',
      year: '',
      frontImg: '',
      frontLeftImg: '',
      frontRightImg: '',
      backLeftImg: '',
      backRightImg: '',
      backImg: '',
      interiorLeftImg: '',
      interiorImg: '',
      interiorBackImg: '',
      leftImg: '',
      rightImg: '',
      color: '',
      state: '',
      city: '',
      km: '',
      price: '',
      description: '',
      available: '',
    }),
    offerSuccess: false,
    userOffersList: types.optional(types.array(OfferListType), []),
    acceptOfferSuccess: false,
    acceptOfferError: false,
    rejectOfferSuccess: false,
    rejectOfferError: false,
  })
    .actions((self) => ({
      updateField: (field, value) => {
        applySnapshot(self, { ...self, [field]: value });
      },
      update: (data) => {
        applySnapshot(self, {
          ...self,
          ...data,
        });
      },
    }))
    .actions((self) => ({
      resetStore: () => {
        self.updateField('submitting', false);
        self.updateField('submitError', false);
        self.updateField('submitErrorMsg', '');
        self.updateField('offerType', '');
        self.updateField('offerPrice', '');
        self.updateField('offerPriceError', false);
        self.updateField('interchangeType', '');
        self.updateField('interchangeTypeError', false);
        self.updateField('selectedUserVehicleId', '');
        self.updateField('offerSuccess', false);
        self.updateField('acceptOfferSuccess', false);
        self.updateField('acceptOfferError', false);
        self.updateField('rejectOfferSuccess', false);
        self.updateField('rejectOfferError', false);
      },
    }))
    .actions((self) => ({
      logoutReset: () => {
        self.resetStore();
      },
    }))
    .actions((self) => ({
      createOffer: flow(function* (data) {
        self.submitError = false;
        self.updateField('offerSuccess', false);
        log('createOffer >>>>', data);
        try {
          self.submitting = true;
          const response = yield axios.post(endpoints.createOffer, data);
          log('createOfferResponse >>>>', response);
          if (response.data.success) {
            self.updateField('offerSuccess', true);
          }
        } catch (err) {
          errorLog('createOfferError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      setSelectedUserVehicle: (selectedVehicle) => {
        log('setSelectedUserVehicle >>>>', selectedVehicle);
        self.selectedUserVehicle = cast(selectedVehicle);
      },
      getUserOffers: flow(function* (userId) {
        self.submitError = false;
        log('getUserOffers >>>>', userId);
        try {
          self.submitting = true;
          const response = yield axios.get(endpoints.getUserOffersList(userId));
          log('getUserOffersResponse >>>>', response);
          if (response.data.success) {
            self.userOffersList = cast(response.data.offerList);
          }
        } catch (err) {
          errorLog('getUserOffersError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      acceptOffer: flow(function* (data) {
        self.acceptOfferError = false;
        self.updateField('acceptOfferSuccess', false);
        log('acceptOffer >>>>', data);
        try {
          const response = yield axios.post(endpoints.acceptOffer, data);
          log('acceptOfferResponse >>>>', response);
          if (response.data.success) {
            self.updateField('acceptOfferSuccess', true);
          } else {
            self.acceptOfferError = true;
            self.submitErrorMsg = response?.data?.msg;
          }
        } catch (err) {
          errorLog('acceptOfferError >>>>', err);
          self.acceptOfferError = true;
          self.submitErrorMsg = err?.msg;
        }
      }),
      rejectOffer: flow(function* (data) {
        self.acceptOfferError = false;
        self.updateField('rejectOfferSuccess', false);
        log('rejectOffer >>>>', data);
        try {
          const response = yield axios.post(endpoints.rejectOffer, data);
          log('rejectOfferResponse >>>>', response);
          if (response.data.success) {
            self.updateField('rejectOfferSuccess', true);
          } else {
            self.rejectOfferError = true;
            self.submitErrorMsg = response?.data?.msg;
          }
        } catch (err) {
          errorLog('rejectOfferError >>>>', err);
          self.rejectOfferError = true;
          self.submitErrorMsg = err?.msg;
        }
      }),
    }))
);

export default OffersStore;
