import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = createStyles({
  ToastContainer: () => ({
    position: 'fixed',
    top: '10px',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    zIndex: 99999,
  }),
});

export default makeStyles(useStyles);
