import { types, applySnapshot, flow, cast } from 'mobx-state-tree';
import { toJS } from 'mobx';
import axios from 'axios';

import Common from '../common';
import endpoints from '../../config/endpoints';

const debug = require('debug');

const log = debug('store:registerVehicles:log');
const errorLog = debug('store:registerVehicles:error');

const BrandType = types.model({
  brand: types.string,
  brandId: types.string,
  logo: types.string,
});

const SelectedBrandType = types.model({
  brand: types.string,
  brandId: types.string,
  logo: types.string,
});

const YearType = types.model({
  year: types.string,
});

const SelectedYearType = types.model({
  year: types.string,
});

const ModelType = types.model({
  model: types.string,
  modelId: types.string,
});

const SelectedModelType = types.model({
  model: types.string,
  modelId: types.string,
});

const SubModelType = types.model({
  subModel: types.string,
  subModelId: types.string,
});

const SelectedSubModelType = types.model({
  subModel: types.string,
  subModelId: types.string,
});

const EngineType = types.model({
  vehicleId: types.string,
  lts: types.string,
  cil: types.string,
  valv: types.string,
});

const SelectedEngineType = types.model({
  vehicleId: types.string,
  lts: types.string,
  cil: types.string,
  valv: types.string,
});

const RegisterVehiclesStore = types.compose(
  Common,
  types.model({
    submitting: false,
    activeStep: 0,
    submitError: false,
    submitErrorMsg: '',
    registerSuccess: false,
    steps: types.optional(types.array(types.string), ['Selecciona la marca', 'Selecciona el año', 'Datos de tu auto', 'Sube fotos de tu auto']),
    brands: types.optional(types.array(BrandType), []),
    selectedBrand: types.optional(SelectedBrandType, {
      brand: '',
      brandId: '',
      logo: '',
    }),
    years: types.optional(types.array(YearType), []),
    selectedYear: types.optional(SelectedYearType, {
      year: '',
    }),
    models: types.optional(types.array(ModelType), []),
    selectedModel: types.optional(SelectedModelType, {
      model: '',
      modelId: '',
    }),
    modelsError: false,
    subModels: types.optional(types.array(SubModelType), []),
    selectedSubModel: types.optional(SelectedSubModelType, {
      subModel: '',
      subModelId: '',
    }),
    subModelsError: false,
    engines: types.optional(types.array(EngineType), []),
    selectedEngine: types.optional(SelectedEngineType, {
      vehicleId: '',
      lts: '',
      cil: '',
      valv: '',
    }),
    enginesError: false,
    frontImg: '',
    frontLeftImg: '',
    frontRightImg: '',
    backLeftImg: '',
    backRightImg: '',
    backImg: '',
    interiorLeftImg: '',
    interiorImg: '',
    interiorBackImg: '',
    leftImg: '',
    rightImg: '',
    km: '',
    kmError: false,
    price: '',
    priceError: false,
    description: '',
    descriptionError: false,
    plates: '',
    platesError: false,
  })
    .actions((self) => ({
      updateField: (field, value) => {
        applySnapshot(self, { ...self, [field]: value });
      },
      update: (data) => {
        applySnapshot(self, {
          ...self,
          ...data,
        });
      },
    }))
    .actions((self) => ({
      resetStore: () => {
        self.updateField('submitting', false);
        self.updateField('activeStep', 0);
        self.updateField('submitError', false);
        self.updateField('submitErrorMsg', '');
        self.updateField('registerSuccess', false);
        self.updateField('frontImg', '');
        self.updateField('frontLeftImg', '');
        self.updateField('frontRightImg', '');
        self.updateField('backLeftImg', '');
        self.updateField('backRightImg', '');
        self.updateField('backImg', '');
        self.updateField('interiorLeftImg', '');
        self.updateField('interiorImg', '');
        self.updateField('interiorBack', '');
        self.updateField('leftImg', '');
        self.updateField('rightImg', '');
        self.updateField('modelsError', false);
        self.updateField('subModelsError', false);
        self.updateField('enginesError', false);
        self.updateField('km', '');
        self.updateField('kmError', false);
        self.updateField('selectedBrand', {
          brand: '',
          brandId: '',
          logo: '',
        });
        self.updateField('selectedYear', {
          year: '',
        });
        self.updateField('selectedModel', {
          model: '',
          modelId: '',
        });
        self.updateField('selectedSubModel', {
          subModel: '',
          subModelId: '',
        });
        self.updateField('selectedEngine', {
          vehicleId: '',
          lts: '',
          cil: '',
          valv: '',
        });
        self.updateField('price', '');
        self.updateField('priceError', false);
        self.updateField('plates', '');
        self.updateField('platesError', false);
      },
      resetVehicleDataForm: () => {
        self.updateField('selectedModel', {
          model: '',
          modelId: '',
        });
        self.updateField('selectedSubModel', {
          subModel: '',
          subModelId: '',
        });
        self.updateField('selectedEngine', {
          vehicleId: '',
          lts: '',
          cil: '',
          valv: '',
        });
        self.updateField('km', '');
        self.updateField('modelsError', false);
        self.updateField('subModelsError', false);
        self.updateField('enginesError', false);
        self.updateField('kmError', false);
        self.updateField('price', '');
        self.updateField('priceError', false);
        self.updateField('description', '');
        self.updateField('descriptionError', false);
        self.updateField('plates', '');
        self.updateField('platesError', false);
      },
    }))
    .actions((self) => ({
      logoutReset: () => {
        self.resetStore();
      },
    }))
    .actions((self) => ({
      getBrands: flow(function* () {
        self.submitError = false;
        log('getBrands >>>>');
        try {
          self.submitting = true;
          const response = yield axios.get(endpoints.getBrands);
          log('getBrandsResponse >>>>', response);
          if (response.data.success) {
            self.brands = cast(response.data.brands);
          }
        } catch (err) {
          errorLog('getBrandsError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      setSelectedBrand: (selectedBrand) => {
        log('setSelectedBrand >>>>', selectedBrand);
        const updateSelectedBrand = SelectedBrandType.create({
          brand: selectedBrand.brand,
          brandId: selectedBrand.brandId,
          logo: selectedBrand.logo,
        });
        self.update({
          selectedBrand: updateSelectedBrand,
        });
        self.updateField('activeStep', 1);
      },
      getYears: flow(function* () {
        self.submitError = false;
        log('getYears >>>>');
        try {
          self.submitting = true;
          const response = yield axios.get(endpoints.getYears(self.selectedBrand.brandId));
          log('getYearsResponse >>>>', response);
          if (response.data.success) {
            self.years = cast(response.data.years);
          }
        } catch (err) {
          errorLog('getYearsError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      setSelectedYear: (selectedYear) => {
        log('setSelectedYear >>>>', selectedYear);
        const updateSelectedYear = SelectedYearType.create({
          year: selectedYear.year,
        });
        self.update({
          selectedYear: updateSelectedYear,
        });
        self.updateField('activeStep', 2);
      },
      getModels: flow(function* () {
        self.submitError = false;
        log('getModels >>>>');
        try {
          self.submitting = true;
          const response = yield axios.get(endpoints.getModels(self.selectedBrand.brandId, self.selectedYear.year));
          log('getModelsResponse >>>>', response);
          if (response.data.success) {
            self.models = cast(response.data.models);
          }
        } catch (err) {
          errorLog('getModelsError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      setSelectedModel: (selectedModel) => {
        log('setSelectedModel >>>>', toJS(selectedModel));
        const updateSelectedModel = SelectedModelType.create({
          model: selectedModel.model,
          modelId: selectedModel.modelId,
        });
        self.update({
          selectedModel: updateSelectedModel,
        });
      },
      getSubModels: flow(function* () {
        self.submitError = false;
        log('getSubModels >>>>');
        try {
          self.submitting = true;
          const response = yield axios.get(endpoints.getSubModels(self.selectedModel.modelId, self.selectedYear.year));
          log('getSubModelsResponse >>>>', response);
          if (response.data.success) {
            self.subModels = cast(response.data.subModels);
          }
        } catch (err) {
          errorLog('getSubModelsError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      setSelectedSubModel: (selectedSubModel) => {
        log('setSelectedSubModel >>>>', toJS(selectedSubModel));
        const updateSelectedSubModel = SelectedSubModelType.create({
          subModel: selectedSubModel.subModel,
          subModelId: selectedSubModel.subModelId,
        });
        self.update({
          selectedSubModel: updateSelectedSubModel,
        });
      },
      getEngines: flow(function* () {
        self.submitError = false;
        log('getEngines >>>>');
        try {
          self.submitting = true;
          const response = yield axios.get(endpoints.getEngines(self.selectedSubModel.subModelId, self.selectedYear.year));
          log('getEnginesResponse >>>>', response);
          if (response.data.success) {
            self.engines = cast(response.data.engines);
          }
        } catch (err) {
          errorLog('getEnginesError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      setSelectedEngine: (selectedEngine) => {
        log('setSelectedEngines >>>>', toJS(selectedEngine));
        const updateSelectedEngine = SelectedEngineType.create({
          vehicleId: selectedEngine.vehicleId,
          lts: selectedEngine.lts,
          cil: selectedEngine.cil,
          valv: selectedEngine.valv,
        });
        self.update({
          selectedEngine: updateSelectedEngine,
        });
      },
      createRegister: flow(function* (registerData) {
        self.submitError = false;
        self.updateField('registerSuccess', false);
        log('createRegister >>>>');
        try {
          self.submitting = true;
          const response = yield axios.post(endpoints.createVehicleRegister, registerData);
          log('createRegisterResponse >>>>', response);
          if (response.data.success) {
            self.updateField('registerSuccess', true);
          }
        } catch (err) {
          errorLog('createRegisterError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
    }))
);

export default RegisterVehiclesStore;
