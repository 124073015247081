import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route } from 'react-router-dom';

import CustomBackdrop from '../CustomBackdrop';
import useStore from '../../hooks/useStore';
import ROUTES from '../../constants/routes';

const AuthRoute = ({ component: Component, ...routeProps }) => {
  const { loginStore, userStore } = useStore();
  const isAuthenticated = loginStore.isAuthenticated === '1';

  useEffect(async () => {
    await userStore.getUserData();
  }, []);

  if (userStore.submitting) {
    return <CustomBackdrop loading bgDark />;
  }

  if (isAuthenticated) {
    return (
      <Route
        {...routeProps}
        render={(props) => (
          <Component {...props} />
        )}
      />
    );
  }
  if (loginStore.isAuthenticated === '0') {
    return (
      <Redirect from="*" to={ROUTES.LANDING} />
    );
  }
  return <CustomBackdrop loading={false} bgDark />;
};

export default observer(AuthRoute);
