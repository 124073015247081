import React, { useState, createContext } from 'react';
import { v4 as uuid } from 'uuid';
import ToastWrapper from '.';

const ToastContext = createContext({
  addToast: () => {
    // Empty Function
  },
  toasts: [],
});

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const removeToast = (idToRemove) => {
    setToasts((currentToasts) => currentToasts.filter(({ id }) => id !== idToRemove));
  };

  const onClose = ({ timeout, id }) => {
    clearTimeout(timeout);
    removeToast(id);
  };

  const addToast = (toast) => {
    setToasts((currentToasts) => {
      const id = toast.id ?? uuid();
      const duration = toast.duration ?? 6000;

      const timeout = setTimeout(() => {
        removeToast(id);
      }, duration + 2000);

      return [
        ...currentToasts,
        {
          ...toast,
          id,
          duration,
          onClose: () => {
            onClose({ timeout, id });
          },
        },
      ];
    });
  };

  return (
    <ToastContext.Provider value={{ addToast, toasts }}>
      {children}
      <ToastWrapper toasts={toasts} />
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
