import { FC } from 'react';
import Modal from '@material-ui/core/Modal';
import useModal from '../../hooks/useModal';

import useStyles from './styles';

const ModalWrapper: FC = () => {
  const { component: Component } = useModal();
  const css = useStyles();

  if (!Component) return null;

  return (
    <Modal open={true}>
      <div className={css.modalWrapper}>
        <Component.component {...Component.props} />
      </div>
    </Modal>
  );
};

export default ModalWrapper;
