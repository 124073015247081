import { useContext } from 'react';
import { ModalContext } from '../../components/ModalWrapper/ModalContext';

const useModal = () => {
  const { modalComponent, setModalComponent } = useContext(ModalContext);

  const showModal = ({ component, props = {} }) => {
    setModalComponent({ component, props });
  };

  const closeModal = () => {
    setModalComponent(null);
  };

  return { showModal, closeModal, component: modalComponent };
};

export default useModal;
