import React from 'react';
import loadable from '@loadable/component';
import CustomBackdrop from '../../components/CustomBackdrop';

const Landing = loadable(
  () =>
    import(
      /* webpackChunkName: "Landing" */
      './landing'
    ),
  {
    fallback: <CustomBackdrop loading />,
  },
);

export default Landing;
