import React, { useState, useEffect, forwardRef } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';

import successIcon from '../../assets/images/checkbox-filled.svg';
import errorIcon from '../../assets/images/error-filled.svg';
import crossIcon from '../../assets/images/cross-icon.svg';

import { useAlertStyles, useSnackbarStyles, useIconStyles } from './styles';

const SuccessIcon = () => <img src={successIcon} alt="Success" />;
const ErrorIcon = () => <img src={errorIcon} alt="Error" />;
const ExitIcon = () => <img src={crossIcon} alt="Close" />;

const Transition = forwardRef((
  props,
  ref,
) => (
  <Slide
    {...props}
    direction="down"
    timeout={{
      enter: 300,
      exit: 300,
    }}
    ref={ref}
  />
));

function Toast({
  title = '',
  message = '',
  type = 'success',
  vertical = 'top',
  horizontal = 'center',
  duration,
  onClose,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const alertClasses = useAlertStyles({ type });
  const snackbarClasses = useSnackbarStyles();
  const buttonClasses = useIconStyles();
  const isError = type === 'error';
  const AlertIcon = isError ? ErrorIcon : SuccessIcon;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen(false);
    }, duration);

    return () => clearTimeout(timeout);
  }, [duration]);

  return (
    <Snackbar
      TransitionComponent={Transition}
      anchorOrigin={{ vertical, horizontal }}
      open={isOpen}
      classes={snackbarClasses}
    >
      <Alert
        className={isError ? 'toast-Error' : 'toast-Success'}
        classes={alertClasses}
        elevation={2}
        data-testid="toastComponent"
        variant="filled"
        icon={<AlertIcon />}
        action={(
          <Button
            classes={buttonClasses}
            onClick={onClose}
            color="inherit"
            size="small"
          >
            <ExitIcon />
          </Button>
        )}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
