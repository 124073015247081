import React from 'react';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import useStyles from './styles';

const DrawerComponent = ({ onClose, options, children }) => {
  const css = useStyles();
  const { showCloseButton, title, subtitle, anchor } = options;

  const IconBtn = () => {
    if (anchor === 'right') {
      return <ChevronLeftIcon />;
    }

    if (anchor === 'bottom') {
      return <KeyboardArrowDownIcon />;
    }

    return <ChevronRightIcon />;
  };

  const CloseButton = () => (
    <IconButton
      data-testid="close-btn"
      size="small"
      aria-label="close"
      className={css.icon}
      onClick={onClose}
    >
      <IconBtn />
    </IconButton>
  );

  const headerCls = clsx(css.header, { [css.headerReverse]: anchor === 'left' });
  const subtitleContainerCls = clsx(css.subtitleContainer, {
    [css.subtitleContainerReverse]: anchor === 'left',
  });

  return (
    <>
      <div className={headerCls} data-testid="header">
        <div className={subtitleContainerCls}>
          {showCloseButton && <CloseButton />}
          <span data-testid="subtitle" className={css.subtitle}>
            {subtitle}
          </span>
        </div>
        <h2 className={css.title} data-testid="title">
          {title}
        </h2>
      </div>
      {children}
    </>
  );
};

export default DrawerComponent;
