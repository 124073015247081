import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Lottie from 'lottie-react';

import loaderAnimation from '../../assets/lotties/loader-animation.json';
import useStyles from './styles';

const CustomBackdrop = ({ loading, bgDark }) => {
  const clx = useStyles();
  return (
    <Backdrop className={bgDark ? clx.backdropDark : clx.backdrop} open={loading}>
      {loading && (
        <Lottie className={clx.spinner} animationData={loaderAnimation} />
      )}
    </Backdrop>
  );
};

export default CustomBackdrop;
