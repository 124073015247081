import React, { useState, createContext } from 'react';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import DrawerComponentHandler from './ComponentHandler';
import useStyles from './styles';

const DrawerContext = createContext({
  drawerComponent: null,
  setDrawerComponent: () => {
    //
  },
  closeDrawer: () => {
    //
  },
});

const DrawerProvider = ({ children }) => {
  const [drawerComponent, setDrawerComponent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const css = useStyles();

  const handleSetDrawerComponent = (component) => {
    setDrawerComponent(component);
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <DrawerContext.Provider
      value={{ drawerComponent, setDrawerComponent: handleSetDrawerComponent, closeDrawer }}
    >
      {children}
      <Drawer
        anchor={drawerComponent?.options?.anchor || 'right'}
        open={isOpen}
        onClose={closeDrawer}
        className={drawerComponent?.options?.drawerClassName}
        classes={{
          root: drawerComponent?.options?.rootClassName || '',
          paper: drawerComponent?.options?.useDefaultWidth
            ? clsx(css.defaultWidthPaper, drawerComponent?.options?.paperClassName)
            : drawerComponent?.options?.paperClassName || css.paper,
        }}
      >
        {drawerComponent && (
          <DrawerComponentHandler component={drawerComponent} onClose={closeDrawer} />
        )}
      </Drawer>
    </DrawerContext.Provider>
  );
};

export { DrawerContext, DrawerProvider };
