import Cookies from 'js-cookie';
import {
  cookieName,
} from '../config/variables';

const debug = require('debug');

const log = debug('util:authCookie:log');

const isLocalhost = window.location.hostname === 'ita-app.devlocal.com';
const cookieConfig = isLocalhost ? {} : { domain: process.env.REACT_APP_COOKIE_DOMAIN };

export const getExpiration = (minutes) =>
  new Date(new Date().getTime() + 60 * minutes * 1000);

/* Auth cookie */
export default class AuthCookie {
  static setCookie(value) {
    log('setCookie >>>> ', cookieName, value);
    Cookies.set(cookieName, value, { expires: getExpiration(45), ...cookieConfig });
  }

  static getCookie() {
    const cookie = Cookies.get(cookieName);
    return cookie || '';
  }

  static deleteCookie() {
    log('deleteCookie >>>> ', cookieName);
    Cookies.set(cookieName, '', { expires: getExpiration(45), ...cookieConfig });
  }
}
