import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ROUTES from './constants/routes';
import useDebugger from './hooks/useDebugger';
import UnAuthRoute from './components/UnAuthRoute';
import AuthRoute from './components/AuthRoute';

// routes
import Landing from './pages/Landing';
// import Browser from './pages/Browser';
// import Login from './pages/Login';
// import SignIn from './pages/SignIn';
// import ForgotPass from './pages/ForgotPass';
// import RegisterVehicles from './pages/RegisterVehicles';
// import VehicleDetail from './pages/VehicleDetail';
// import UserProfile from './pages/UserProfile';

const App = () => {
  useDebugger();
  return (
    <Switch>
      <Route path={ROUTES.LANDING}>
        <Landing />
      </Route>
      {/* <Route path={ROUTES.BROWSER}>
        <Browser />
      </Route>
      <UnAuthRoute path={ROUTES.LOGIN}>
        <Login />
      </UnAuthRoute>
      <UnAuthRoute path={ROUTES.SIGN_IN}>
        <SignIn />
      </UnAuthRoute>
      <UnAuthRoute path={ROUTES.FORGOT_PASS}>
        <ForgotPass />
      </UnAuthRoute>
      <Route path={ROUTES.VEHICLE_DETAIL}>
        <VehicleDetail />
      </Route>
      <AuthRoute path={ROUTES.REGISTER_VEHICLES}>
        <RegisterVehicles />
      </AuthRoute>
      <AuthRoute path={ROUTES.USER_PROFILE}>
        <UserProfile />
      </AuthRoute>
      */}
      <Redirect from="*" to={ROUTES.LANDING} />
    </Switch>
  );
};

export default App;
