import React from 'react';
import Toast from '../Toast';
import useStyles from './styles';

const ToastWrapper = ({ toasts }) => {
  const css = useStyles();

  return (
    <div className={css.ToastContainer}>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          type={toast.type}
          title={toast.title}
          message={toast.message}
          duration={toast.duration}
          onClose={toast.onClose}
        />
      ))}
    </div>
  );
};

export default ToastWrapper;
