import { applySnapshot, flow, types, getRoot } from 'mobx-state-tree';
import axios from 'axios';
import Common from '../common';
import endpoints from '../../config/endpoints';

const debug = require('debug');

const log = debug('store:user:log');
const errorLog = debug('store:user:error');

const UserStore = types.compose(
  Common,
  types.model({
    submitting: false,
    userName: types.maybeNull(types.string),
    userPhone: types.maybeNull(types.string),
    userEmail: types.maybeNull(types.string),
    userId: types.maybeNull(types.string),
    userCity: types.maybeNull(types.string),
    userState: types.maybeNull(types.string),
    updateUserEmailSuccess: false,
    updateUserEmailError: false,
    submitError: false,
    submitErrorMsg: '',
    submittingForm: false,
    passError: false,
    confirmPassError: false,
    updateUserPassSuccess: false,
    updateUserPassError: false,
  })
    .actions((self) => ({
      updateField: (field, value) => {
        applySnapshot(self, { ...self, [field]: value });
      },
      delay: (ms) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    }))
    .actions((self) => ({
      resetStore: () => {
        self.updateField('submitting', false);
        self.updateField('userName', null);
        self.updateField('userPhone', null);
        self.updateField('userEmail', null);
        self.updateField('userId', null);
        self.updateField('userCity', null);
        self.updateField('userState', null);
        self.updateField('submitError', false);
        self.updateField('submitErrorMsg', '');
        self.updateField('submittingForm', false);
      },
    }))
    .actions((self) => ({
      logoutReset: () => {
        self.resetStore();
      },
    }))
    .actions((self) => ({
      getUserData: flow(function* () {
        const { loginStore } = getRoot(self);
        log('getUserData >>>>');
        try {
          loginStore.updateField('isAuthenticated', '');
          self.submitting = true;
          const response = yield axios.get(endpoints.getUserData);
          log('getUserDataResponse >>>>', response);
          if (response.data.success) {
            const { userCity, userEmail, userId, userName, userPhone, userState } = response.data.userData;
            loginStore.updateField('isAuthenticated', '1');
            self.updateField('userCity', userCity);
            self.updateField('userEmail', userEmail);
            self.updateField('userId', userId);
            self.updateField('userName', userName);
            self.updateField('userPhone', userPhone);
            self.updateField('userState', userState);
          }
        } catch (err) {
          errorLog('getUserDataError >>>>', err);
          loginStore.updateField('isAuthenticated', '0');
          loginStore.resetStore();
        } finally {
          yield self.delay(1000);
          self.submitting = false;
        }
      }),
      updateUserEmail: flow(function* (data) {
        self.submitError = false;
        self.updateUserEmailSuccess = false;
        self.updateUserEmailError = false;
        log('updateUserEmail >>>>', data);
        try {
          self.submittingForm = true;
          const response = yield axios.post(endpoints.updateUserEmail, data);
          log('updateUserEmailResponse >>>>', response);
          if (response.data.success) {
            self.updateUserEmailSuccess = true;
          }
        } catch (err) {
          errorLog('updateUserEmailError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
          self.updateUserEmailSuccess = false;
          self.updateUserEmailError = true;
        } finally {
          self.submittingForm = false;
        }
      }),
      updateUserPass: flow(function* (data) {
        self.submitError = false;
        self.updateUserPassSuccess = false;
        self.updateUserPassError = false;
        log('updateUserPass >>>>', data);
        try {
          self.submittingForm = true;
          const response = yield axios.post(endpoints.updateUserPass, data);
          log('updateUserPassResponse >>>>', response);
          if (response.data.success) {
            self.updateUserPassSuccess = true;
          }
        } catch (err) {
          errorLog('updateUserPassError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
          self.updateUserPassSuccess = false;
          self.updateUserPassError = true;
        } finally {
          self.submittingForm = false;
        }
      }),
    })),
);

export default UserStore;
