import { types, applySnapshot, flow, cast } from 'mobx-state-tree';
import axios from 'axios';

import Common from '../common';
import endpoints from '../../config/endpoints';

const debug = require('debug');

const log = debug('store:vehicles:log');
const errorLog = debug('store:vehicles:error');

const VehicleItemType = types.model({
  id: types.string,
  userId: types.string,
  registerDate: types.string,
  vehicleId: types.string,
  brand: types.string,
  model: types.string,
  subModel: types.string,
  engine: types.string,
  year: types.string,
  frontImg: types.string,
  frontLeftImg: types.string,
  frontRightImg: types.string,
  backLeftImg: types.string,
  backRightImg: types.string,
  backImg: types.string,
  interiorLeftImg: types.string,
  interiorImg: types.string,
  interiorBackImg: types.string,
  leftImg: types.string,
  rightImg: types.string,
  color: types.string,
  state: types.string,
  city: types.string,
  km: types.string,
  price: types.string,
  description: types.string,
  available: types.string,
});

const BrandType = types.model({
  brand: types.string,
});

const ModelType = types.model({
  model: types.string,
});

const YearType = types.model({
  year: types.string,
});

const StateType = types.model({
  state: types.string,
});

const RegisterVehiclesStore = types.compose(
  Common,
  types.model({
    submitting: false,
    submitError: false,
    submitErrorMsg: '',
    updateVehicleSubmitting: false,
    vehiclesList: types.optional(types.array(VehicleItemType), []),
    totalPages: 0,
    page: 1,
    filterText: types.maybeNull(types.string),
    registeredBrands: types.optional(types.array(BrandType), []),
    filterBrand: 'all',
    registeredModels: types.optional(types.array(ModelType), []),
    filterModel: 'all',
    registeredYears: types.optional(types.array(YearType), []),
    filterYear: 'all',
    registeredStates: types.optional(types.array(StateType), []),
    filterState: 'all',
    vehicleDetail: types.optional(VehicleItemType, {
      id: '',
      userId: '',
      registerDate: '',
      vehicleId: '',
      brand: '',
      model: '',
      subModel: '',
      engine: '',
      year: '',
      frontImg: '',
      frontLeftImg: '',
      frontRightImg: '',
      backLeftImg: '',
      backRightImg: '',
      backImg: '',
      interiorLeftImg: '',
      interiorImg: '',
      interiorBackImg: '',
      leftImg: '',
      rightImg: '',
      color: '',
      state: '',
      city: '',
      km: '',
      price: '',
      description: '',
      available: '',
    }),
    vehiclesUserList: types.optional(types.array(VehicleItemType), []),
    updateVehiclePriceSuccess: false,
    updateVehicleDescriptionSuccess: false,
    deleteVehicleSuccess: false,
  })
    .actions((self) => ({
      updateField: (field, value) => {
        applySnapshot(self, { ...self, [field]: value });
      },
      update: (data) => {
        applySnapshot(self, {
          ...self,
          ...data,
        });
      },
    }))
    .actions((self) => ({
      resetStore: () => {
        self.updateField('submitting', false);
        self.updateField('updateVehicleSubmitting', false);
        self.updateField('activeStep', 0);
        self.updateField('submitError', false);
        self.updateField('submitErrorMsg', '');
        self.updateField('vehiclesList', []);
        self.updateField('totalPages', 0);
        self.updateField('page', 1);
      },
    }))
    .actions((self) => ({
      logoutReset: () => {
        self.resetStore();
      },
    }))
    .actions((self) => ({
      getVehiclesList: flow(function* () {
        self.submitError = false;
        log('getVehiclesList >>>>');
        try {
          const response = yield axios.post(endpoints.getVehiclesList, {
            page: self.page,
            filterText: self.filterText,
            filterBrand: self.filterBrand,
            filterYear: self.filterYear,
            filterState: self.filterState,
            filterModel: self.filterModel,
          });
          log('getVehiclesListResponse >>>>', response);
          if (response.data.success) {
            self.vehiclesList = cast(response.data.vehicleList);
            self.updateField('totalPages', response.data.totalPages);
          }
        } catch (err) {
          errorLog('getVehiclesListError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        }
      }),
      getVehiclesRegisteredBrands: flow(function* () {
        self.submitError = false;
        log('getVehiclesRegisteredBrands >>>>');
        try {
          const response = yield axios.get(endpoints.getVehiclesRegisteredBrands);
          log('getVehiclesRegisteredBrandsResponse >>>>', response);
          if (response.data.success) {
            self.registeredBrands = cast(response.data.brands);
          }
        } catch (err) {
          errorLog('getVehiclesRegisteredBrandsError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        }
      }),
      getVehiclesRegisteredModels: flow(function* (brand) {
        self.submitError = false;
        log('getVehiclesRegisteredModels >>>>', brand);
        try {
          const response = yield axios.get(endpoints.getVehiclesRegisteredModels(brand));
          log('getVehiclesRegisteredModelsResponse >>>>', response);
          if (response.data.success) {
            self.registeredModels = cast(response.data.models);
          }
        } catch (err) {
          errorLog('getVehiclesRegisteredModelsError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        }
      }),
      getVehiclesRegisteredYears: flow(function* () {
        self.submitError = false;
        log('getVehiclesRegisteredYears >>>>');
        try {
          const response = yield axios.get(endpoints.getVehiclesRegisteredYears);
          log('getVehiclesRegisteredYearsResponse >>>>', response);
          if (response.data.success) {
            self.registeredYears = cast(response.data.years);
          }
        } catch (err) {
          errorLog('getVehiclesRegisteredYearsError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        }
      }),
      getVehiclesRegisteredStates: flow(function* () {
        self.submitError = false;
        log('getVehiclesRegisteredStates >>>>');
        try {
          const response = yield axios.get(endpoints.getVehiclesRegisteredStates);
          log('getVehiclesRegisteredStatesResponse >>>>', response);
          if (response.data.success) {
            self.registeredStates = cast(response.data.states);
          }
        } catch (err) {
          errorLog('getVehiclesRegisteredStatesError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        }
      }),
      getVehicleDetail: flow(function* (registerId) {
        self.submitError = false;
        self.submitting = true;
        log('getVehicleDetail >>>>', registerId);
        try {
          const response = yield axios.get(endpoints.getVehicleDetail(registerId));
          log('getVehicleDetailResponse >>>>', response);
          if (response.data.success) {
            self.vehicleDetail = cast(response.data.registerDetail);
          }
        } catch (err) {
          errorLog('getVehicleDetailError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      getAvailableVehiclesUserList: flow(function* (userId) {
        self.submitError = false;
        self.submitting = true;
        log('getAvailableVehiclesUserList >>>>', userId);
        try {
          const response = yield axios.get(endpoints.getAvailableVehiclesUserList(userId));
          log('getAvailableVehiclesUserListResponse >>>>', response);
          if (response.data.success) {
            self.vehiclesUserList = cast(response.data.vehicleList);
          }
        } catch (err) {
          errorLog('getAvailableVehiclesUserListError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      getVehiclesUserList: flow(function* (userId) {
        self.submitError = false;
        self.submitting = true;
        log('getVehiclesUserList >>>>', userId);
        try {
          const response = yield axios.get(endpoints.getVehiclesUserList(userId));
          log('getVehiclesUserListResponse >>>>', response);
          if (response.data.success) {
            self.vehiclesUserList = cast(response.data.vehicleList);
          }
        } catch (err) {
          errorLog('getVehiclesUserListError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      updateVehiclePrice: flow(function* (data) {
        self.submitError = false;
        self.updateVehicleSubmitting = true;
        self.updateVehiclePriceSuccess = false;
        log('updateVehiclePrice >>>>', data);
        try {
          const response = yield axios.post(endpoints.updateVehiclePrice, data);
          log('updateVehiclePriceResponse >>>>', response);
          if (response.data.success) {
            self.updateVehiclePriceSuccess = true;
          }
        } catch (err) {
          errorLog('updateVehiclePriceError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
          self.updateVehiclePriceSuccess = false;
        } finally {
          self.updateVehicleSubmitting = false;
        }
      }),
      updateVehicleDescription: flow(function* (data) {
        self.submitError = false;
        self.updateVehicleSubmitting = true;
        self.updateVehicleDescriptionSuccess = false;
        log('updateVehicleDescription >>>>', data);
        try {
          const response = yield axios.post(endpoints.updateVehicleDescription, data);
          log('updateVehicleDescriptionResponse >>>>', response);
          if (response.data.success) {
            self.updateVehicleDescriptionSuccess = true;
          }
        } catch (err) {
          errorLog('updateVehicleDescriptionError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
          self.updateVehicleDescriptionSuccess = false;
        } finally {
          self.updateVehicleSubmitting = false;
        }
      }),
      deleteVehicle: flow(function* (data) {
        self.submitError = false;
        self.updateVehicleSubmitting = true;
        self.deleteVehicleSuccess = false;
        log('deleteVehicle >>>>', data);
        try {
          const response = yield axios.post(endpoints.deleteVehicle, data);
          log('deleteVehicleResponse >>>>', response);
          if (response.data.success) {
            self.deleteVehicleSuccess = true;
          }
        } catch (err) {
          errorLog('deleteVehicleError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
          self.deleteVehicleSuccess = false;
        } finally {
          self.updateVehicleSubmitting = false;
        }
      }),
    }))
);

export default RegisterVehiclesStore;
