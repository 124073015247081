import { types, applySnapshot, flow, getRoot } from 'mobx-state-tree';
import axios from 'axios';

import Common from '../common';
import endpoints from '../../config/endpoints';
import AuthCookie from '../../utils/authCookie';

const debug = require('debug');

const log = debug('store:login:log');
const errorLog = debug('store:login:error');

const LoginStore = types.compose(
  Common,
  types.model({
    submitting: false,
    isAuthenticated: '',
    submitError: false,
    submitErrorMsg: '',
    loginSuccess: false,
    resetSuccess: false,
    redirectionPath: '',
  })
    .actions((self) => ({
      updateField: (field, value) => {
        applySnapshot(self, { ...self, [field]: value });
      },
    }))
    .actions((self) => ({
      resetStore: () => {
        self.updateField('isAuthenticated', '0');
        self.updateField('submitError', false);
        self.updateField('loginSuccess', false);
        self.updateField('resetSuccess', false);
        self.updateField('submitErrorMsg', '');
      },
      resetForm: () => {
        self.updateField('submitError', false);
        self.updateField('loginSuccess', false);
        self.updateField('resetSuccess', false);
        self.updateField('submitErrorMsg', '');
      },
    }))
    .actions((self) => ({
      logoutReset: () => {
        const { userStore, signInStore, registerVehiclesStore } = getRoot(self);
        AuthCookie.deleteCookie();
        self.resetStore();
        userStore.logoutReset();
        signInStore.logoutReset();
        registerVehiclesStore.logoutReset();
      },
    }))
    .actions((self) => ({
      login: flow(function* ({ email, pass }) {
        self.submitError = false;
        log('login >>>>', email, pass);
        try {
          self.submitting = true;
          const response = yield axios.post(endpoints.login, {
            email,
            pass,
          });
          log('loginResponse >>>>', response);
          if (response.data.success) {
            const { token } = response.data.userData;
            AuthCookie.setCookie(token);
            self.isAuthenticated = '1';
            self.loginSuccess = true;
          }
        } catch (err) {
          errorLog('loginError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
      resetPass: flow(function* ({ email }) {
        self.submitError = false;
        log('resetPass >>>>', email);
        try {
          self.submitting = true;
          const response = yield axios.post(endpoints.resetPass, {
            email,
          });
          log('resetPassResponse >>>>', response);
          if (response.data.success) {
            self.resetSuccess = true;
          }
        } catch (err) {
          errorLog('resetPassError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
    }))
);

export default LoginStore;
