import axios from 'axios';

import AuthCookie from '../utils/authCookie';
import { baseUrl, env } from './variables';
import endpoints from './endpoints';
import ROUTES from '../constants/routes';

const AUTH_ERROR = 'AUTH_ERROR';
const PERMISSION_ERROR = 'PERMISSION_ERROR';

const axiosConfig = () => {
  axios.defaults.baseURL = baseUrl;
  axios.interceptors.request.use((config) => {
    config.headers.Accept = 'application/json';
    config.headers['Ita-Auth'] = AuthCookie.getCookie();
    config.headers['Ita-Api-Version'] = env;
    config.headers['Content-Type'] = 'application/json';
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      const { error, errorCode, msg } = response.data;
      const requestUrl = response?.config?.url || '';
      const noAuthMsg = msg === 'No autorizado';
      const isNoAuth = noAuthMsg
        || errorCode === PERMISSION_ERROR
        || errorCode === AUTH_ERROR
        || response.status === 401;

      const isAuthRequest = [
        endpoints.getBrands,
        endpoints.createVehicleRegister,
        endpoints.createOffer,
        endpoints.updateUserEmail,
        endpoints.updateUserPass,
        endpoints.updateVehicleDescription,
        endpoints.updateVehiclePrice,
      ].includes(requestUrl);

      const isLikeAuthRequest = requestUrl.includes('/filters/years/')
        || requestUrl.includes('/filters/models/')
        || requestUrl.includes('/filters/sub-models/')
        || requestUrl.includes('/filters/engine/')
        || requestUrl.includes('/vehicles/list/');

      if (isNoAuth || noAuthMsg) {
        const token = AuthCookie.getCookie();
        if (token) AuthCookie.deleteCookie();
      }

      if (isNoAuth && (isAuthRequest || isLikeAuthRequest)) {
        window.location.href = ROUTES.LOGIN;
      }

      if (error) return Promise.reject(response.data);
      return response;
    },
    (error) => Promise.reject(error),
  );
};

export default axiosConfig;
