import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    backdropDark: {
      zIndex: 200000000,
      background: theme.itaTheme.crow,
    },
    backdrop: {
      zIndex: 200000000,
      color: '#fff',
    },
    spinner: {
      width: 200,
    },
  }));

export default useStyles;
