import { types, applySnapshot, flow } from 'mobx-state-tree';
import axios from 'axios';

import Common from '../common';
import endpoints from '../../config/endpoints';

const debug = require('debug');

const log = debug('store:signIn:log');
const errorLog = debug('store:signIn:error');

const SignInStore = types.compose(
  Common,
  types.model({
    submitting: false,
    submitError: false,
    submitErrorMsg: '',
    userCreated: false,
  })
    .actions((self) => ({
      updateField: (field, value) => {
        applySnapshot(self, { ...self, [field]: value });
      },
    }))
    .actions((self) => ({
      resetStore: () => {
        self.updateField('submitErrorMsg', '');
        self.updateField('userCreated', false);
      },
    }))
    .actions((self) => ({
      logoutReset: () => {
        self.resetStore();
      },
    }))
    .actions((self) => ({
      signIn: flow(function* (data) {
        log('signIn >>>>');
        try {
          self.submitting = true;
          const response = yield axios.post(endpoints.createUser, {
            user: data.name,
            email: data.email,
            pass: data.pass,
            phone: data.phone,
            city: data.municipality,
            state: data.state,
          });
          log('signIn >>>>', response);
          if (response.data.success) {
            self.updateField('userCreated', true);
          }
        } catch (err) {
          errorLog('signInError >>>>', err);
          self.submitError = true;
          self.submitErrorMsg = err?.msg;
        } finally {
          self.submitting = false;
        }
      }),
    })),
);

export default SignInStore;
